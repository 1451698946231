<template>
<div class="tw-border tw-border-solid tw-border-gray-100 tw-p-4 tw-shadow-md" :class="{'tw-animate-pulse tw-pointer-events-none': deleteHolidayStatus.isLoading}">
    <label class="form__label tw-my-0">
        <span class="form__labelTitle">STANDARD WORKING DAYS</span>
    </label>

    <div>
        <div class="tw-inline-flex tw-gap-4" :class="{'tw-animate-pulse tw-pointer-events-none': updateWorkingDaysStatus.isLoading}">
            <label v-for="d in days" class="form__label form__label_row- tw-m-0 tw-p-0 tw-justify-center tw-items-center">
                <div class="tw-inline-flex tw-flex-col tw-items-center tw-gap-1">
                    <span class="form__labelTitle tw-uppercase tw-text-sm">{{d}}</span>
                    <input
                    type="checkbox"
                    class="form__radio"
                    hidden
                    :value="d"
                    v-model="workingDays"
                    />
                    <span class="form__checkbox_icon tw-m-0"></span>
                </div>
            </label>
        </div>
    </div>


    <InlinePreloader v-if="holidaysLoading" message="loading...."></InlinePreloader>
    <div v-else class="lg:tw-flex-row tw-flex tw-flex-col-reverse tw-gap tw-mb-16 tw-gap-4">
        <div class="lg:tw-w-2/5">
            <label class="form__label">
                <span class="form__labelTitle">HOLIDAYS</span>
            </label>


            <Popover v-slot="{ open }" class="popover tw-relative tw-inline-block">
                <PopoverButton as="button" class="btn btn_default popover-button tw-text-base">
                    <span> Select Year: {{ moment(selectedDate).format('YYYY') }} </span>
                    <DropDownChevron :is-open="open"></DropDownChevron>
                </PopoverButton>

                <transition name="popover">
                <PopoverPanel v-slot="{ close }" class="popover-panel tw-right-auto tw-left-0 tw-p-4 tw-border tw-border-solid tw-border-gray-200 tw-shadow-md tw-rounded">
                    <div class="tw-max-w-xs body__calendar">
                        <vue-cal
                            :selected-date="selectedDate"

                            :time="false"
                            active-view="years"
                            hide-view-selector
                            :disable-views="['year', 'week', 'day', 'month']"
                            @cell-focus="($event) => { close(); selectedDate = $event; }"
                            :min-date="new Date()"
                            >
                        </vue-cal>
                    </div>
                </PopoverPanel>
                </transition>
            </Popover>
            <div class="tw-text-black-gray tw-mt-4  tw-space-y-4">
                <div v-for="(val, key) in holidaysByYear" :key="key">
                    <div class="tw-bg-gray-200 tw-inline-block tw-p-1 tw-rounded tw-px-2 tw-text-gray-800">{{key}}</div>
                    <div class="tw-space-y-1">
                        <p v-for="(day, index) in val" :key="index" class="tw-flex tw-justify-between">
                            <span>{{ moment(day.start).format('DD MMMM') }} - {{ day.title }}</span>
                            <button @click="handleDeleteHoliday(day)" class="tw-btn-primary-success tw-p-0 tw-w-auto tw-bg-transparent hover:tw-bg-transparent tw-text-red-800">
                                <svg-icon name="solid/trash"></svg-icon>
                            </button>
                        </p>

                    </div>
                </div>
            </div>

        </div>

        <div class="body__calendar tw-max-w-sm">
            <vue-cal
            small
            :time="false"
            active-view="month"
            hide-view-selector
            :disable-views="['years', 'year', 'week', 'day']"
            @cell-click="handleDateSelect($event)"
            :selected-date="selectedDate < new Date() ? new Date() : selectedDate"
            :events="holidays"
            :min-date="new Date()"
            >
                <template v-slot:weekday-heading="{ heading }">{{ heading.label.substring(0, 2) }}</template>
                <template v-slot:cell-content="{ cell, view, events, goNarrower }" class="tw-bg-pink-400">
                    <div class="vuecal__cell-date tw-flex tw-w-full tw-h-full tw-items-center tw-justify-center" :class="{'tw-bg-gray-200 tw-border tw-border-white tw-border-solid tw-pointer-events-none': workingDays && !workingDays.includes( moment(cell.formattedDate).format('ddd').toUpperCase() )}">{{ cell.content }}</div>
                    <div class="vuecal__cell-events-count tw-bg-green-600" v-if="events.length > 1 ">{{ events.length }}</div>
                </template>
            </vue-cal>
        </div>
    </div>

    <NewHolidayModal v-if="showAddHolidayModal" @close="showAddHolidayModal = false" :selectedDate="selectedDate"></NewHolidayModal>

</div>
</template>

<script setup>
import { ref, computed } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import _ from 'lodash'
import useGetQuotingPortalSetup from '../../../hooks/quoting-portal-setup/useGetQuotingPortalSetup';
import useUpdateWorkingDays from '../../../hooks/quoting-portal-setup/useUpdateWorkingDays';
import useGetHolidays from '../../../hooks/quoting-portal-setup/useGetHolidays';
import useDeleteHoliday from '../../../hooks/quoting-portal-setup/useDeleteHoliday';

import moment from 'moment';
import VueCal from 'vue-cal'
// import 'vue-cal/dist/vuecal.css'
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import DropDownChevron from '../../../components/icons/DropDownChevron.vue';
import NewHolidayModal from './NewHolidayModal.vue';
import InlinePreloader from '../../../components/parts/inlinePreloader.vue';

const { data, isLoading } = useGetQuotingPortalSetup()
const { updateWorkingDays, updateWorkingDaysStatus } = useUpdateWorkingDays()
const { data: holidaysData, isLoading: holidaysLoading } = useGetHolidays()
const { deleteHoliday, deleteHolidayStatus } = useDeleteHoliday()


const days = ['MON','TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
const workingDays = ref(data.value.standard_working_days || [])


watch(workingDays, (val, oldVal) => {
  updateWorkingDays(val)
})

const showAddHolidayModal = ref(false)
const selectedDate = ref(new Date())

const handleDateSelect = (e) => {
  showAddHolidayModal.value = true
  selectedDate.value = e
}


const holidays = computed(() => {
  const data = holidaysData.value || []
  return data.map( i => ({
    start: i.date,
    end: i.date,
    title: i.description,
    year: moment(i.date).format('YYYY'),
    month: moment(i.date).format('MMMM'),
    id: i.id,
  }))
})

const holidaysByYear = computed(() => {
  let data = holidays.value.filter(i => i.year === moment(selectedDate.value).format('YYYY'))
  data = _.sortBy(data, ['start'])
  return _.groupBy(data, (i) => i.month)
})

const handleDeleteHoliday = async (item) => {
  await deleteHoliday({
    id: item.id
  })
}


</script>

<style scoped lang="scss">
.body {
  &__calendar {
    height: 280px;
    ::v-deep .vuecal {
      box-shadow: none;
      &__heading {
        font-weight: bold;
        background: rgba(11, 53, 83, 0.1);
        font-size: 15px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #1C2833;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          right: -1px;
          bottom: -1px;
          border: 1px solid rgba(11, 53, 83, 0.3)
        }
      }
      &__title-bar {
        background-color: transparent;
        font-weight: bold;
        font-size: 17px;
        line-height: 100%;
        height: 45px;
        letter-spacing: 0.02em;
        color: #1C2833;
      }
      &__weekdays-headings {
        padding-right: 0;
        border-right: 1px solid rgba(11, 53, 83, 0.3);
      }
      &__cell {
        font-size: 15px;
        line-height: 100%;
        color: rgba(28, 40, 51, 0.9);
        &--selected {
            background: rgba(11, 53, 83, 0.1);
        }
          &:hover {
        cursor: pointer;
           background: rgba(11, 53, 83, 0.1);
          }
        &--out-of-scope {
          color: rgba(11, 53, 83, 0.5);
        }
        &--disabled {
          color: rgba(11, 53, 83, 0.5);
        }
        &--has-events {
          background: rgba(89, 182, 146, 0.2);
          cursor: pointer;
          &.vuecal__cell--selected {
            background: rgba(89, 182, 146, 0.2);
          }
          &:hover {
            background: rgba(89, 182, 146, 0.4)
          }
        }
        &:before {
          border-color: rgba(11, 53, 83, 0.2)
        }
        &-events-count {
          left: auto;
          right: 0;
          top: 0;
          transform: translateX(0);
          background: rgba(11, 53, 83, 0.3);
          border-radius: 0px 0px 0px 6px;
          color: #fff;
          min-width: 15px;
          height: 15px;
          padding: 2px;
          font-weight: bold;
          font-size: 11px;
          line-height: 100%;
          letter-spacing: 0.02em;
        }
      }
    }

  }
}

</style>
