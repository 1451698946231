import { useQuery } from "vue-query";
import { reactive } from '@vue/reactivity'
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";

import store from '@/store/store'

const fetchData = async () => {

  const response = await httpReq.get(`ordering-portal/account-information-maintenance/quoting-portal-setup/x`);
  return response.data.data;
};

export default function useGetQuotingPortalSetup() {

  const queryKey = ["quoting-portal-setup"];

  return useQuery(
    queryKey,
    () => fetchData(),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      //   placeholderData: [],
        // staleTime: Infinity,
      onError: (error) => {
        store.commit("errorsModal/openModal", error.response.data.errors);
      },
    }
  );
}
