<script setup>
import { ref, computed } from '@vue/reactivity'
import { watch } from "@vue/runtime-core";
import _ from 'lodash'
import useGetAvailableServiceAreasAsGeoJson from "../../../hooks/quoting-portal-setup/useGetAvailableServiceAreasAsGeoJson";
import { useStore } from 'vuex';

const VUE_APP_MAP_SQUARE_KM = process.env.VUE_APP_MAP_SQUARE_KM || 400
const store = useStore()

const props = defineProps({
    selectedRegions: {
        type: Array,
        default: []
    }
})

const emits = defineEmits(['toggleSuburbSelection', 'addAllSuburbsOnTheMap', 'removeAllSuburbsOnTheMap'])

const NW_SW_MaxDistance = 282845 //in meters, For 200km * 200km area
const areaLenghtKm = 10
const isWithinMaxArea = ref(false)
const viewportCoordinates = ref({})

const { data, isLoading, isRefetching } = useGetAvailableServiceAreasAsGeoJson(viewportCoordinates, isWithinMaxArea)

const myMapRef = ref();

watch([() => isLoading.value, () => isRefetching.value], ([value, isRef],[oldVal, oldRef]) => {
  console.log('isLoading', value, isRef)
  const load = value || isRef
  setTimeout(() => {
      store.commit('preloader/preloader', load);  
    }, load? 0 : 400);
})

watch(myMapRef, googleMap => {
    if (googleMap) {
    googleMap.$mapPromise.then(map=> {
        onMaploaded(map)
    })
    }
});

const latestPointsOnViewPort = ref([])

watch(
  isRefetching,
  (newVal, oldVal) => {
    if(oldVal && !newVal) {
      latestPointsOnViewPort.value = data.value

      myMapRef.value.$mapPromise.then(map=> {
          data.value.forEach(x => {

            if(!map.data.getFeatureById(x.geojson.properties.id)) {
              map.data.addGeoJson( _.cloneDeep(x.geojson), {idPropertyName: 'id'} )
            }
          })
          map.data.setStyle(styleFeature);
      })
    }
  },
  { immediate: true }
);

const addSelectedRegions = () => {
  myMapRef.value.$mapPromise.then(map=> {
      props.selectedRegions.forEach(x => {

        if(!map.data.getFeatureById(x.id)) {
          map.data.addGeoJson( _.cloneDeep(x.geojson), {idPropertyName: 'id'} )
        }
      })
    map.data.setStyle(styleFeature);

  })
}

watch(() => props.selectedRegions, (newVal,oldVal) => {
  if(myMapRef.value) {
      addSelectedRegions()
  }

}, {immediate: true})



const mapStyle = [
  {
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ visibility: "on" }, { color: "#fcfcfc" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ visibility: "on" }, { color: "#bfd4ff" }],
  },
];


const styleFeature = (feature) => {
    const id = feature.getId()
    const isSelected = !!props.selectedRegions.find(x => x.id === id)

  let outlineWeight = 0.5,
    zIndex = 1;

  if (feature.getProperty("state") === "hover") {
    outlineWeight = zIndex = 2;
  }
  return {
    strokeWeight: outlineWeight,
    strokeColor: "#fff",
    zIndex: 1,
    fillColor: isSelected? 'green': 'rgba(0,0,0,0.5)',
    fillOpacity: 0.75,
    visible: true,
  };
}


const onMaploaded = (map) => {
addSelectedRegions()
console.log('onloaded....')
    map.data.setStyle(styleFeature);
    map.data.addListener("mouseover", mouseInToRegion);
    map.data.addListener("mouseout", mouseOutOfRegion);
    map.data.addListener("click", mouseClickOnRegion);

    map.addListener("bounds_changed", () => mapViewPortChanged(map));

}

const mapViewPortChanged = _.debounce((map) => {
  const bounds = map.getBounds()
  const NECorner = bounds.getNorthEast();
  const SWCorner = bounds.getSouthWest();
  const NWCorner = new google.maps.LatLng(NECorner.lat(), SWCorner.lng());
  const SECorner = new google.maps.LatLng(SWCorner.lat(), NECorner.lng());

  let distance = google.maps.geometry.spherical.computeDistanceBetween(NECorner,SWCorner);

  let idealDistanceInMeters = Math.sqrt((VUE_APP_MAP_SQUARE_KM * VUE_APP_MAP_SQUARE_KM) + (VUE_APP_MAP_SQUARE_KM * VUE_APP_MAP_SQUARE_KM))*1000
  isWithinMaxArea.value = distance <= idealDistanceInMeters


  viewportCoordinates.value = {
    ne_lat: NECorner.lat(),
    ne_lng: NECorner.lng(),
    sw_lat: SWCorner.lat(),
    sw_lng: SWCorner.lng(),
    nw_lat: NWCorner.lat(),
    nw_lng: NWCorner.lng(),
    se_lat: SECorner.lat(),
    se_lng: SECorner.lng(),
  }

  // map.data.setStyle(styleFeature);

}, 300)

const mouseClickOnRegion = (e) => {
    const id = e.feature.getId()
    emits('toggleSuburbSelection', id)
}


const mouseInToRegion = (e) => {

  e.feature.setProperty("state", "hover");

}


const mouseOutOfRegion = (e) => {
  // reset the hover state, returning the border to normal
  e.feature.setProperty("state", "normal");
}

</script>

<template>
  <div class="tw-relative">
    <div v-if="!isWithinMaxArea" class="tw-p-2 tw-bg-yellow-100 tw-text-yellow-600 tw-absolute tw-z-10 tw-bottom-0 tw-left-0">Zoom in to view the service areas.</div>
    <GMapMap
            :options="{ streetViewControl: false, componentRestrictions: { country: 'aus' },fields: ['address_components']}"
            :center="{lat: -21.526921227434247, lng: 141.91216888394834}"
            :zoom="10"
            map-type-id="terrain"
            style="width: 100vw; height: 9000px"
            class="tw-h-96 "
            ref="myMapRef"
        >
        </GMapMap>
        <div class="tw-flex tw-justify-between tw-gap-4 tw-absolute">
          <button class="btn btn_default" @click="emits('addAllSuburbsOnTheMap', latestPointsOnViewPort)">Add all suburbs</button>
          <button class="btn btn_default" @click="emits('removeAllSuburbsOnTheMap', latestPointsOnViewPort)">Remove all suburbs</button>
        </div>
  </div>

 <!-- <pre>{{selectedRegions.length}}</pre> -->

</template>
