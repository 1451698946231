<script setup>
import { ref } from '@vue/reactivity'
import _ from 'lodash'
import SelectSuburbComponent from './SelectSuburbComponent.vue'
import useGetServiceAreaSuburbs from '../../../hooks/quoting-portal-setup/useGetServiceAreaSuburbs';
import InlinePreloader from '../../../components/parts/inlinePreloader.vue';
import NoResults from '../../../components/NoResults.vue';
import useCreateServiceAreaSuburb from '../../../hooks/quoting-portal-setup/useCreateServiceAreaSuburb';
import useDeleteServiceAreaSuburb from '../../../hooks/quoting-portal-setup/useDeleteServiceAreaSuburb';
import useSyncServiceAreaSuburbs from '../../../hooks/quoting-portal-setup/useSyncServiceAreaSuburbs';
import MapWithServiceAreas from './MapWithServiceAreas.vue';
import { useStore } from 'vuex';
import { watch } from 'vue';
const { data, isLoading } = useGetServiceAreaSuburbs()
const { createServiceAreaSuburb, createServiceAreaSuburbStatus } = useCreateServiceAreaSuburb()
const { deleteServiceAreaSuburb, deleteServiceAreaSuburbStatus } = useDeleteServiceAreaSuburb()
const { syncServiceAreaSuburbs, syncServiceAreaSuburbsStatus  } = useSyncServiceAreaSuburbs()

const store = useStore()

const handlePlaceChange = (e) => {
  console.log('Place change...', e)
}

watch(() => syncServiceAreaSuburbsStatus.isLoading, (value) => {
    setTimeout(() => {
      store.commit('preloader/preloader', value);  
    }, value? 0 : 400);
})

const handleSuburbSelection = async (suburb) => {
  const isSelected = !! data.value.find(i => i.id === suburb.id)
  if(isSelected) return

  let ids = data.value.map(x => x.id)
  ids.push(suburb.id)
  syncServiceAreaSuburbs({ids})
}

const handleToggleSuburbSelection = (id) => {
  const isSelected = !! data.value.find(i => i.id === id)
  let ids = data.value.map(x => x.id)

  if(isSelected) {
    ids = ids.filter(x => x !== id)
  }else {
    ids.push(id)
  }
  syncServiceAreaSuburbs({ids})
}

const removeSuburb = async (suburb) => {
  let ids = data.value.map(x => x.id)
  ids = ids.filter(x => x !== suburb.id)
  syncServiceAreaSuburbs({ids})

}

const addAllSuburbsOnTheMap = async (suburbs) => {
  const selectedIds = data.value.map(x => x.id)
  const idsOnMapViewport = suburbs.map(x => x.id)
  const final = Array.from(new Set( [...selectedIds, ...idsOnMapViewport] ))

  syncServiceAreaSuburbs({ids: final})
}

const removeAllSuburbsOnTheMap = async (suburbs) => {
  const selectedIds = data.value.map(x => x.id)
  const idsOnMapViewport = suburbs.map(x => x.id)
  const final = selectedIds.filter(x => !idsOnMapViewport.includes(x))

  syncServiceAreaSuburbs({ids: final})
}



</script>

<template>
    <div class="lg:tw-flex lg:tw-max-w-none md:tw-max-w-xl tw-gap-4">   
        <div class="lg:tw-w-2/5"> 
          <label class="form__label tw-mt-0">
              <span class="form__labelTitle">LIST OF SUBURBS</span>
          </label>
          <div class="">
            <SelectSuburbComponent @selected="handleSuburbSelection"></SelectSuburbComponent>
          </div>

          <InlinePreloader v-if="isLoading" message="loading suburbs..."></InlinePreloader>
          <div v-else class="tw-my-4">
            <table class=" tw-min-w-full tw-divide-y tw-divide-gray-200 tw-divide-solid tw-shadow">
              <thead class="tw-bg-gray-50">
                
                <th scope="col" class="tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                  Suburb
                </th>
                <th scope="col" class="tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                  Post code
                </th>
                
                <th scope="col" class="tw-w-7 tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                  
                </th>
              </thead> 
              <tbody class="tw-divide-solid tw-divide-y tw-divide-gray-200">
                <template v-if="data.length === 0">
                  <tr>
                    <td colspan="8">
                      <NoResults class="tw-h-52"></NoResults>                    
                    </td>
                  </tr>
                </template>
                <template v-else>       
                
                  <tr v-for="(item, index) in data" :key="item.id" :class="{'tw-animate-pulse tw-bg-gray-200 tw-pointer-events-none': syncServiceAreaSuburbsStatus.isLoading}">                  
                    <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                      {{item.suburb}}
                    </td>
                    <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                      {{item.postcode}}
                    </td>
                    
                    <td class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                        <button type="button" @click="removeSuburb(item, index)" class="tw-btn-tertiary-success tw-text-red-900 tw-bg-transparent">
                          <svg-icon name="solid/trash"></svg-icon>
                        </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="tw-flex-1 tw-bg-white tw-mb-8">
        <MapWithServiceAreas :selectedRegions="data" @toggleSuburbSelection="handleToggleSuburbSelection" @addAllSuburbsOnTheMap="addAllSuburbsOnTheMap" @removeAllSuburbsOnTheMap="removeAllSuburbsOnTheMap"/>
        </div>
    </div>
</template>