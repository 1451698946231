import { useQuery } from "vue-query";
import { reactive } from '@vue/reactivity'
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";

import store from '@/store/store'

const fetchData = async () => {
  
  const response = await httpReq.get(`ordering-portal/account-information-maintenance/area-of-services`);
  return response.data.data;
};

export default function useGetServiceAreaSuburbs() {

  const queryKey = ["area-of-services"];

  return useQuery(
    queryKey,
    () => fetchData(),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      //   placeholderData: [],
        // staleTime: Infinity,
      onError: (error) => {
        store.commit("errorsModal/openModal", error.response.data.errors);
      },
    }
  );
}
