import {useInfiniteQuery, useQuery} from "vue-query";

import { reactive, ref } from '@vue/reactivity'
// import httpClient from "@/http/httpClient";
// import store from "@/store";
import { httpReq } from "@/htttpReq";


const fetchData = async () => {
  const response = await httpReq.get(`ordering-portal/account-information-maintenance/price-lists?for_qp=true`);
  return response.data.data
};

export default function useGetPricelistsForQPSetup() {

  const queryKey = reactive(["price-lists-for-qp-setup"]);
  return useQuery(
    queryKey,
    () => fetchData(),
    {
      refetchOnWindowFocus: false,
      // keepPreviousData: true,
      //   placeholderData: [],
        staleTime: Infinity,
      onError: (error) => {
        // store.commit("errorsModal/openModal", error.response.data.errors);
      },
    }
  );
}
