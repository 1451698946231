import { useQuery } from "vue-query";
import { reactive } from '@vue/reactivity'
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";

import store from '@/store/store'

const fetchData = async (search) => {
    if(search.length === 0 ) return []
    // const search = searchAll.viewportCoordinates
    const response = await httpReq.get(`ordering-portal/account-information-maintenance/area-of-services/search-address`, {
      params: {
        "filter[search]": search
      }
    });

    return response.data.data
}

export default function useSearchServiceArea(search) {

  const queryKey = reactive(["search-service-area", {search}]);
  // const queryKey = reactive(["dealers-infinite", { queryParams }]);

  return useQuery(
    queryKey,
    () => fetchData(search.value),
    {
      refetchOnWindowFocus: false,
      // enabled: search.value.length >= 5,
      keepPreviousData: false,
      //   placeholderData: [],
        // staleTime: Infinity,
      onError: (error) => {
        store.commit("errorsModal/openModal", error.response.data.errors);
      },
    }
  );
}
