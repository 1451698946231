<script setup>
import {ref} from 'vue'
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from '@headlessui/vue'


const selected = ref({})
const selectedAddress = ref({})
import useGetServiceAreaSuburbs
  from "../../../../hooks/quoting-portal-setup/useGetServiceAreaSuburbs";
import useCalculateDistance from "../../../../hooks/quoting-portal-setup/useCalculateDistance";
import {watch} from "@vue/runtime-core";

const props = defineProps({show: {default: false}})
const emits = defineEmits(['update:show'])
const {data, isLoading} = useGetServiceAreaSuburbs()
const {distance, calculate,reset} = useCalculateDistance()

watch(() => selected.value, (value) => {
 if(!_.isEmpty(value)) calculate({suburb_id:value.id})
})

watch(async() => selectedAddress.value, async (value) => {
  if(!_.isEmpty(value)) await calculate({suburb_id: selected.value.id, address_id: value})
  emits('update:show',false)
  selected.value = {}
  selectedAddress.value = {}
  await reset()
})



</script>
<template>

  <base-modal
    v-model:show="show"
    @closed="emits('update:show',false)"
    :title="`Select ${distance && !distance.is_shipping_address_same_as_main_address ? ' address ' :' suburb '} to calculate distance`"
  >
    <template #modal-content>

      <div class="tw-w-full tw-px-2 tw-py-6">
        <div class="tw-w-full tw-max-w-md tw-mx-auto">
          <RadioGroup v-if="!distance"  v-model="selected">
            <RadioGroupLabel class="tw-sr-only"></RadioGroupLabel>
            <div  class="tw-space-y-2">
              <RadioGroupOption
                as="template"
                v-for="suburb in data"
                :key="suburb.id"
                :value="suburb"
                v-slot="{ active, checked }"
              >
                <div
                  :class="[
                active
                  ? 'tw-ring-2 tw-ring-offset-2 tw-ring-offset-sky-300 tw-ring-white tw-ring-opacity-60'
                  : '',
                checked ? 'tw-bg-primary tw-bg-opacity-75 tw-text-white ' : 'tw-bg-white ',
              ]"
                  class="tw-relative tw-flex tw-px-5 tw-py-4 tw-rounded-lg tw-shadow-md tw-cursor-pointer tw-focus:tw-outline-none"
                >
                  <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
                    <div class="tw-flex tw-items-center">
                      <div class="tw-text-sm">
                        <RadioGroupLabel
                          as="p"
                          :class="checked ? 'tw-text-white' : 'tw-text-gray-900'"
                          class="tw-font-medium"
                        >
                          {{ suburb.suburb }}, {{ suburb.postcode }}
                        </RadioGroupLabel>
                        <RadioGroupDescription
                          as="span"
                          :class="checked ? 'tw-text-sky-100' : 'tw-text-gray-500'"
                          class="tw-inline"
                        >
                          <span> {{ suburb.state }}</span>
                          <!--                          <span aria-hidden="true"> &middot; </span>
                                                    <span>{{ suburbstate }}</span>-->
                        </RadioGroupDescription>
                      </div>
                    </div>
                    <div v-show="checked" class="tw-flex-shrink-0 tw-text-white">
                      <svg class="tw-w-6 tw-h-6" viewBox="0 0 24 24" fill="none">
                        <circle
                          cx="12"
                          cy="12"
                          r="12"
                          fill="#fff"
                          fill-opacity="0.2"
                        />
                        <path
                          d="M7 13l3 3 7-7"
                          stroke="#fff"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
          <RadioGroup v-else v-model="selectedAddress">
            <RadioGroupLabel class="tw-sr-only"></RadioGroupLabel>
            <div  class="tw-space-y-2">
              <RadioGroupOption
                as="template"
                :key="distance.primary_address.id"
                :value="distance.primary_address.id"
                v-slot="{ active, checked }"
              >
                <div
                  :class="[
                active
                  ? 'tw-ring-2 tw-ring-offset-2 tw-ring-offset-sky-300 tw-ring-white tw-ring-opacity-60'
                  : '',
                checked ? 'tw-bg-primary tw-bg-opacity-75 tw-text-white ' : 'tw-bg-white ',
              ]"
                  class="tw-relative tw-flex tw-px-5 tw-py-4 tw-rounded-lg tw-shadow-md tw-cursor-pointer tw-focus:tw-outline-none"
                >
                  <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
                    <div class="tw-flex tw-items-center">
                      <div class="tw-text-sm">
                        <RadioGroupLabel
                          as="p"
                          :class="checked ? 'tw-text-white' : 'tw-text-gray-900'"
                          class="tw-font-medium"
                        >
                          {{ distance.primary_address.address }}, {{ distance.primary_address.suburb }}
                        </RadioGroupLabel>
                        <RadioGroupDescription
                          as="span"
                          :class="checked ? 'tw-text-sky-100' : 'tw-text-gray-500'"
                          class="tw-inline"
                        >
                          <span> {{ distance.primary_address.postcode }}</span>
                          <span aria-hidden="true"> &middot; </span>
                          <span>{{ distance.primary_address?.state_details.name }}</span>
                        </RadioGroupDescription>
                      </div>
                    </div>
                    <div v-show="checked" class="tw-flex-shrink-0 tw-text-white">
                      <svg class="tw-w-6 tw-h-6" viewBox="0 0 24 24" fill="none">
                        <circle
                          cx="12"
                          cy="12"
                          r="12"
                          fill="#fff"
                          fill-opacity="0.2"
                        />
                        <path
                          d="M7 13l3 3 7-7"
                          stroke="#fff"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </RadioGroupOption>
              <RadioGroupOption
                as="template"
                :key="distance.shipping_address.id"
                :value="distance.shipping_address.id"
                v-slot="{ active, checked }"
              >
                <div
                  :class="[
                active
                  ? 'tw-ring-2 tw-ring-offset-2 tw-ring-offset-sky-300 tw-ring-white tw-ring-opacity-60'
                  : '',
                checked ? 'tw-bg-primary tw-bg-opacity-75 tw-text-white ' : 'tw-bg-white ',
              ]"
                  class="tw-relative tw-flex tw-px-5 tw-py-4 tw-rounded-lg tw-shadow-md tw-cursor-pointer tw-focus:tw-outline-none"
                >
                  <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
                    <div class="tw-flex tw-items-center">
                      <div class="tw-text-sm">
                        <RadioGroupLabel
                          as="p"
                          :class="checked ? 'tw-text-white' : 'tw-text-gray-900'"
                          class="tw-font-medium"
                        >
                          {{ distance.shipping_address.address }}, {{ distance.shipping_address.suburb }}
                        </RadioGroupLabel>
                        <RadioGroupDescription
                          as="span"
                          :class="checked ? 'tw-text-sky-100' : 'tw-text-gray-500'"
                          class="tw-inline"
                        >
                          <span> {{ distance.shipping_address.postcode }}</span>
                          <span aria-hidden="true"> &middot; </span>
                          <span>{{ distance.shipping_address?.state_details.name }}</span>
                        </RadioGroupDescription>
                      </div>
                    </div>
                    <div v-show="checked" class="tw-flex-shrink-0 tw-text-white">
                      <svg class="tw-w-6 tw-h-6" viewBox="0 0 24 24" fill="none">
                        <circle
                          cx="12"
                          cy="12"
                          r="12"
                          fill="#fff"
                          fill-opacity="0.2"
                        />
                        <path
                          d="M7 13l3 3 7-7"
                          stroke="#fff"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>
      </div>
    </template>
  </base-modal>
</template>
