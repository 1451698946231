<script setup>
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import _ from 'lodash'
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import useSearchServiceArea from '../../../hooks/quoting-portal-setup/useSearchServiceArea';

const emits = defineEmits(['selected'])


const searchValue = ref('')
const searchValueForAPI = ref('')

watch(searchValue, _.debounce((val, oldVal) => {
    searchValueForAPI.value = val
}, 300))


const { data, isLoading, isRefetching } = useSearchServiceArea(searchValueForAPI)

const show = ref(false)

const selectSuburb = (item) => {
    console.log('select....xxxxxx')
    emits('selected', item)
}

const handleOnblur = () => {
    setTimeout(() => {
        show.value = false
    }, 10);
}

</script>

<template>

    <div class="tw-relative">
        <div class="tw-relative">
            <input
                v-model="searchValue"
                autocomplete="off"
                type="search"
                class="form__input tw-input-search"
                name="query"
                placeholder="Start typing to search"
                @focus="show = true"
                @blur="handleOnblur"
            />
            <span class=" tw-absolute tw-right-8 tw-top-1/2 tw-transform tw--translate-y-1/2">
                <svg-icon v-if="isRefetching || isLoading" name="solid/spinner tw-animate-spin" class=""></svg-icon>
            </span>

            
        </div>

        <Menu v-slot="{ open }">
            <transition
            enter-active-class="tw-transition tw-duration-100 tw-ease-out"
            enter-from-class="tw-transform tw-scale-95 tw-opacity-0"
            enter-to-class="tw-transform tw-scale-100 tw-opacity-100"
            leave-active-class="tw-transition tw-duration-75 tw-ease-in"
            leave-from-class="tw-transform tw-scale-100 tw-opacity-100"
            leave-to-class="tw-transform tw-scale-95 tw-opacity-0"
        >
            <div v-show="show">
                <MenuItems static class="tw-overflow-y-auto tw-max-h-80 tw-z-10 tw-absolute tw-right-0 tw-w-full tw-mt-1 tw-origin-top-right tw-bg-white tw-divide-y tw-divide-gray-100 tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                    <MenuItem>
                        <div>
                            <slot name="menu-items">
                                <div class="tw-card tw-text-black-gray tw-overflow-visible tw-z-10">
                                    <div class="tw-card-body tw-p-2-">
                                        <div>
                                            <div v-for="item in data" :key="item.id" class="tw-cursor-pointer hover:tw-bg-gray-100 tw-p-2 tw-space-x-2" @click="selectSuburb(item)">
                                                <span>{{ item.suburb }}</span>
                                                <span>{{ item.state }}</span> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </slot>
                        </div>
                    </MenuItem>
                </MenuItems>
            </div>
            </transition>
        </Menu>
    </div>
</template>