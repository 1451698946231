import {httpReq} from "../../htttpReq";
import {ref} from "vue"
import store from "../../store/store";

export default  function useCalculateDistance (){
  const distance = ref(null)
  const calculate =  async (payload) => {
    store.commit('preloader/preloader', true);
    const response = await httpReq.post(`ordering-portal/account-information-maintenance/area-of-services/maximum-travel-distance`,payload);
    if(!payload.address_id) distance.value = response.data
    store.commit('preloader/preloader', false);

  }

  const reset = async () => {
    distance.value = null
  }

  return {
    distance,
    calculate,
    reset
  }
}
