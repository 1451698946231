import { useMutation, useQueryClient } from "vue-query";
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";
import store from '@/store/store'
import { useToast } from "vue-toastification";
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const toast = useToast();

const action = async (payload) => {
  const { id } = payload;
  await httpReq.post(`ordering-portal/account-information-maintenance/area-of-services`, {
    id: id
  });
};

export default function useCreateServiceAreaSuburb() {
  const cache = useQueryClient();
  const mutation = useMutation((payload) => action(payload), {
    onError: (error) => {
      console.error('Error on useCreateServiceAreaSuburb: ', error)
      store.commit("errorsModal/openModal", _.get(error, 'response.data.errors', [{0:'Something went wrong'}]));
    },
    onSuccess: (data, variables, context) => {
      cache.invalidateQueries(["area-of-services"]);
      toast.success("Service area added.");
    },
    onSettled: (data, error, variables, context) => {},    
  });

  return reactive({
    createServiceAreaSuburb: mutation.mutateAsync,
    createServiceAreaSuburbStatus: mutation,
  });
}
