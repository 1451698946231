import { useMutation, useQueryClient } from "vue-query";
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";
import store from '@/store/store'
import { useToast } from "vue-toastification";
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const toast = useToast();

const action = async (payload) => {
  const { quotingPortalUrl, leadsNotificationEmail, defaultPricelist, maximum_travel_distance, website  } = payload;
  await httpReq.put(`ordering-portal/account-information-maintenance/quoting-portal-setup/1`, {
    subdomain_name: quotingPortalUrl,
    leads_notification_email :leadsNotificationEmail,
    pricelist_header_id : defaultPricelist,
    maximum_travel_distance,
    website,
  });
};

export default function useUpdatePortal() {
  const cache = useQueryClient();
  const mutation = useMutation((payload) => action(payload), {
    onError: (error) => {
      console.error('Error on useUpdatePortal: ', error)
      store.commit("errorsModal/openModal", _.get(error, 'response.data.errors', [{0:'Something went wrong'}]));
    },
    onSuccess: (data, variables, context) => {
      cache.invalidateQueries(["quoting-portal-setup"]);
      toast.success("Settings updated.");
    },
    onSettled: (data, error, variables, context) => {},
  });

  return reactive({
    updatePortal: mutation.mutateAsync,
    updatePortalStatus: mutation,
  });
}
