import { useMutation, useQueryClient } from "vue-query";
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";
import store from '@/store/store'
import { useToast } from "vue-toastification";
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const toast = useToast();

const action = async (payload) => {
  const { description, date  } = payload;
  await httpReq.post(`ordering-portal/account-information-maintenance/calendars`, {
    description, date
  });
};

export default function useCreateNewHoliday() {
  const cache = useQueryClient();
  const mutation = useMutation((payload) => action(payload), {
    onError: (error) => {
      console.error('Error on useCreateNewHoliday: ', error)
      store.commit("errorsModal/openModal", _.get(error, 'response.data.errors', [{0:'Something went wrong'}]));
    },
    onSuccess: (data, variables, context) => {
      cache.invalidateQueries(["holidays"]);
      toast.success("Calendar updated.");
    },
    onSettled: (data, error, variables, context) => {},    
  });

  return reactive({
    createHoliday: mutation.mutateAsync,
    createHolidayStatus: mutation,
  });
}
