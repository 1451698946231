<script setup>
import { ref } from '@vue/reactivity'
import SimpleModal from '../../../components/modals/SimpleModal.vue';
import moment from 'moment';
import useCreateNewHoliday from '../../../hooks/quoting-portal-setup/useCreateNewHoliday';

const props = defineProps({
    selectedDate: {
        type: Date,
        required: true,
    }
})

const emits = defineEmits(['close'])

const { createHoliday, createHolidayStatus } = useCreateNewHoliday()

const description = ref('')

const handleSubmit = async() => {
    await createHoliday({
        date: moment(props.selectedDate).format('YYYY-MM-DD'),
        description: description.value
    })
    emits('close')
}

</script>

<template>
    <SimpleModal @close="emits('close')">
        <div :class="{ 'tw-animate-pulse tw-pointer-events-none': createHolidayStatus.isLoading }">
            <h4>Add new holiday</h4>

            <label class="form__label">
                <span class="form__labelTitle">Date</span>
                    <input
                    type="text"
                    :value="$filters.formatDate(moment(selectedDate))"
                    class="form__input"
                    name="date"
                    disabled
                    />
            </label>
            <form @submit.prevent="handleSubmit">

                <label class="form__label">
                    <span class="form__labelTitle">Description</span>
                        <input
                        type="text"
                        v-model="description"
                        class="form__input"
                        name="description"
                        placeholder="Description"
                        required
                        />
                </label>

                <div>
                    <button :disabled="createHolidayStatus.isLoading" type="submit" class="tw-btn-primary-success">SAVE</button>
                </div>
            </form>
        </div>
    </SimpleModal>
</template>

<style scoped>
    .simple_modal__mask {
        z-index: 1000;
    }
</style>
