import { useMutation, useQueryClient } from "vue-query";
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";
import store from '@/store/store'
import { useToast } from "vue-toastification";
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const toast = useToast();

const action = async (payload) => {
  const { suburb, postCode  } = payload;
  await httpReq.post(`ordering-portal/account-information-maintenance/quoting-portal-setup/update-standard-working-days`, {
    standard_working_days: payload
  });
};

export default function useUpdateWorkingDays() {
  const cache = useQueryClient();
  const mutation = useMutation((payload) => action(payload), {
    onError: (error) => {
      cache.invalidateQueries(["quoting-portal-setup"]);
      console.error('Error on useUpdateWorkingDays: ', error)
      store.commit("errorsModal/openModal", _.get(error, 'response.data.errors', [{0:'Something went wrong'}]));
    },
    onSuccess: (data, variables, context) => {
      cache.invalidateQueries(["quoting-portal-setup"]);
      toast.success("Working days updated.");
    },
    onSettled: (data, error, variables, context) => {},    
  });

  return reactive({
    updateWorkingDays: mutation.mutateAsync,
    updateWorkingDaysStatus: mutation,
  });
}
