import { useQuery } from "vue-query";
import { reactive } from '@vue/reactivity'
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";

import store from '@/store/store'

const fetchData = async (params, isWithinMaxArea) => {
    if(!isWithinMaxArea) return []
    const response = await httpReq.get(`ordering-portal/account-information-maintenance/area-of-services/map-data`, {
      params: {
        "nw_lat": params.nw_lat,
        "nw_lng": params.nw_lng,
        "ne_lat": params.ne_lat,
        "ne_lng": params.ne_lng,
        "se_lat": params.se_lat,
        "se_lng": params.se_lng,
        "sw_lat": params.sw_lat,
        "sw_lng": params.sw_lng
      }
    });

    return response.data.data
}

export default function useGetAvailableServiceAreasAsGeoJson(params, isWithinMaxArea) {

  const queryKey = reactive(["available-service-areas-geojson", {params}]);

  return useQuery(
    queryKey,
    () => fetchData(params.value, isWithinMaxArea.value),
    {
      refetchOnWindowFocus: false,
      enabled: true,
      keepPreviousData: true,
      //   placeholderData: [],
        // staleTime: Infinity,
      onError: (error) => {
        store.commit("errorsModal/openModal", error.response.data.errors);
      },
    }
  );
}
